/*Scrollbar css start from here*/
/* ::-webkit-scrollbar {
  background-color: #fff;
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

::-webkit-scrollbar-button {
  display: none;
} */

/*Scrollbar css end*/

/* body .noPadding {
  padding: 0;
} */

/* html body {
  margin: 0;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2C406E;
  font-family: 'Poppins', sans-serif;
} */

/* body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2C406E;
  font-family: 'Poppins', sans-serif !important;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* a {
  color: #111B53;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
} */
/* 
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  color: #2C406E;
  font-weight: bold;
} */

/* body .textInput {
  padding: 8.5px 10px;
  background-color: #fff;
  border-radius: 8px;
  height: 23px !important;
  min-height: unset;
} */

.thaiDatePiker .ant-input {
  padding: 0 10px;
  height: 40px;
  line-height: 40;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  color: #2c406e;
  /* font-size: 13px; */
  border-radius: 8px;
}

.thaiDatePiker .ant-input:hover {
  border-color: #000;
}

.fullHeight {
  height: 100%;
}

/* .textInputTable {
  padding: 6px 9px;
  line-height: 16px;
  background-color: #fff;
  border-radius: 8px;
} */

.textAreaOuter {
  padding: 9px;
  background-color: #fff;
  line-height: 16px;
  border-radius: 8px;
}

/* .formLabel {
  margin-bottom: 8px;
  color: #2C406E;
  font-weight: 300;
} */
/* 
.dissabledMenuAdmin {
  color: #919eab;
} */

/* body .mainBtn {
  background-color: #111B53;
  color: white;
  box-shadow: unset;
  min-width: 80px;
  padding: 0.5rem 1rem;
}

.mainBtn:hover,
.mainBtn:focus {
  background-color: #2C406E;
} */

.mainBtnBlack {
  background-color: #2c406e !important;
}

.mainBtnBlack:hover,
.mainBtnBlack:focus {
  background-color: #111b53 !important;
}

.mainBtnGreen {
  background-color: #69c775 !important;
}

.mainBtnGreen:hover,
.mainBtnGreen:focus {
  background-color: #2c406e;
}
/* 
.displayFlex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justifyContentCenter {
  justify-content: center;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-6 {
  margin-top: 3rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.ml-4 {
  margin-left: 2rem;
}

.ml-5 {
  margin-left: 2.5rem;
}

.ml-6 {
  margin-left: 3rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

.mr-4 {
  margin-right: 2rem;
}

.mr-5 {
  margin-right: 2.5rem;
}

.mr-6 {
  margin-right: 3rem;
} */

.colorGray {
  color: #c8c8c8;
}

/*********** header start here **************/

/* .headerBox {
  -webkit-box-shadow: 0px 5px 16px -8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 16px -8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 16px -8px rgba(0, 0, 0, 0.1);
  padding: 0.75rem 0;
  margin-bottom: 1.5rem;
  background-color: #fff;
  position: fixed;
  left: 160px;
  right: 0;
  top: 0;
  z-index: 1000;
} */
.headerBox {
  -webkit-box-shadow: 0px 5px 16px -8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 16px -8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 16px -8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  margin-bottom: 1.5rem;
  /* background-color: #fff; */
  position: absolute;
  left: 250px;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 50%;
}

.superAdmin {
  text-align: right;
}

.superAdminnBtn {
  background-color: #2c406e;
  color: #fff;
  min-width: 100px;
  padding: 0 1rem;
  /* font-size: 13px; */
  line-height: 36px;
}

.superAdminnBtn:hover,
.superAdminnBtn:focus {
  background-color: #111b53;
  color: #fff;
}

/* remove from here */
/* .pageHeading {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(56,65,74,.15);
  margin: 1px -1.5rem 1.5rem;
  padding: 10px 1.5rem;
} */

/*********** header End here **************/

/******** sidebar start *********/
.sideNavItem {
  flex-direction: column;
  color: #111b53;
}

/******** sidebar end *********/

/* form align by man */
.frmHelperAlign .Mui-error,
.frmHelperAlign .selectHelper {
  margin-left: 0px;
  margin-right: 0px;
}
.MuiFormLabel-asterisk {
  color: red;
}

/*********** card start *********/
.cardOuter {
  transition: box-shadow 0.3s ease 0s;
  border: 1px dashed #4fbbc8;
  background: #fff;
  border-radius: 0.5rem;
  transition: all 0.3s;
  /* cursor: pointer; */
  position: relative;
}

.cardOuter:hover,
.cardOuter:focus {
  box-shadow: 0 10px 15px rgb(0 0 0 / 5%);
  border: 1px solid #f0f0f0;
  transform: scale(1.05);
}

.cardHeading {
  padding: 1rem 1rem 3.5rem;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 0.5rem 0.5rem 0 0;
}

.cardIconB {
  position: relative;
  text-align: center;
}

.cardIcon {
  background-color: #fff;
  height: 80px;
  text-align: center;
  top: -40px;
  width: 80px;
  border-radius: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  white-space: nowrap;
  box-shadow: 0 5px 10px rgb(0 0 0 / 6%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111b53;
}

.cardI {
  font-size: 2rem;
}

.cardBody {
  padding: 3.5rem 1rem 1rem;
}

.cardText {
  /* font-size: 13px; */
}

/* .cardBottom {
  padding: 0.25rem 1rem;
  border-top: 1px solid #f0f0f0;
} */

.cardIconFooter {
  color: #111b53;
  transition: all 0.3s;
}

.cardIconFooter:hover {
  color: #2c406e;
}

/*********** card end *********/

/*********** card second start *********/

.cardOuterGreenBorder,
.cardOuterGreenBorder:hover,
.cardOuterGreenBorder:focus {
  border-left: 5px solid #00ad09;
}

.cardOuterGrayBorder,
.cardOuterGrayBorder:hover,
.cardOuterGrayBorder:focus {
  border-left: 5px solid #999;
}

.cardOuterRedBorder,
.cardOuterRedBorder:hover,
.cardOuterRedBorder:focus {
  border-left: 5px solid #ce0101;
}

.cardTop {
  display: flex;
  padding: 1rem;
  align-items: center;
}

.cardIconLeft {
  margin-right: 1rem;
}

.cardConfigureText {
  /* font-size: 1rem; */
}

.cardIconIcon {
  font-size: 2rem;
}

.cardNoHover:hover {
  transform: unset;
  cursor: default;
}

.headingLineUp {
  background-color: #2c406e;
  border-radius: 0.25rem;
  color: #fff;
  display: inline;
  font-weight: 500;
  left: 0;
  padding: 0.25rem 0.5rem;
  position: relative;
  top: -32px;
  z-index: 999;
}

.headingLineUpOut {
  display: inline-block;
  left: 1.25rem;
  top: 0;
}

.cardTopMinusMargin {
  margin-top: -14.5px;
  padding-top: 2rem;
}

/*********** card second end *********/

/********** inner pages style start here ***********/
.tableHeadingBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fileInput {
  display: none;
}

.uploadDocBoxLabel {
  border: 1px solid #eaedf1;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.24rem;
  width: 88px;
  display: flex;
  height: 88px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.uploadDocBoxLabel:hover {
  border-color: #4fbbc8;
}

.uploadDocBoxLabelFull {
  width: auto;
  height: 250px;
}

/* .squareIconButton {
  border-radius: 0.5rem;
  padding: 5px;
  background-color: #111B53;
  color: #fff;
}

.squareIconButton:hover,
.squareIconButton:focus {
  background-color: #2C406E;
}

.actionButton {
  background-color: #2C406E;
}

.actionButton:hover,
.actionButton:focus {
  background-color: #111B53;
} */

/********** inner pages style end here ***********/

/*********** login page style start ***********/
.textFieldLogin {
  padding: 14px 10px;
  margin-top: 0;
}

body .loginBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2rem;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 16px -8px rgb(0 0 0 / 10%);
}

.mainBtnLogin {
}

/*********** login page style end ***********/

/*********** manage orders style start *********/
.kpiBox {
  background-color: #f4f4f4;
  padding: 6px 16px;
  border-radius: 8px;
}

.kpiBoxG {
  background-color: #d4f1d6;
}

.kpiBoxR {
  background-color: #ffdede;
}

.kpiBoxY {
  background-color: #fff6b9;
}

.filterLink {
  color: #2c406e;
  margin-right: 0.5rem;
  /* font-size: 1rem; */
  border: 1px solid #f4f8f4;
  height: 36px;
  line-height: 36px;
}

.filterLink:hover {
  background-color: #f4f8f4;
  color: #111b53;
}

.filterLink.selected {
  background-color: #f4f8f4;
  color: #111b53;
}

/* .profileImg {
  border: 1px solid #eaedf1;
  border-radius: .5rem;
  height: 180px;
  margin-bottom: .75rem;
  padding: 0.3rem;
} */

.profileImgMatch {
  border-radius: 0.5rem;
  border: 1px solid #eaedf1;
  border-radius: 0.5rem;
  height: 275px;
  margin-bottom: 0.75rem;
  padding: 0.3rem;
}

/* .documentImg {
  border: 6px solid #f8f8f8;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.3rem;
  border: 1px solid #eaedf1;
} */

.documentImgLastBox {
  width: 250px;
}

.displayTable {
  display: table;
  width: 100%;
}

.displayTableRow {
  display: table-row;
}

.displayTableCell {
  display: table-cell;
}

.pb-05 {
  padding-bottom: 5px;
}

.pr-05 {
  padding-right: 5px;
}

.pl-05 {
  padding-left: 5px;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.textCollan {
  width: 25px;
}

.reviewEditIcon {
  border-radius: 8px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

/*********** manage orders style end *********/
/******* right side filter box start **********/
.sideFilterBoxOut {
  padding: 1rem;
  width: 300px;
}

.sideFilterHeading {
  text-decoration: underline;
  color: #2c406e;
}

.sideFilterHeadingBox {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;
}

.filterResetIcon {
  height: 28px;
  width: 28px;
  background-color: #2c406e;
  color: #fff;
}

.filterResetIcon:hover {
  background-color: #111b53;
}

/******* right side filter box end **********/

/******* pop up start **********/
.popupTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #eaedf1; */
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.uploadBox {
  border-radius: 0.5rem;
  text-align: center;
  padding: 1.5rem;
  cursor: pointer;
  background-color: #fff;
  transition: all 0.3s;
  height: 100%;
  box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
  color: #2c406e;
  border: 1px solid #2c406e;
}

.uploadBox label {
  cursor: pointer;
}

.uploadBox:hover,
.uploadBox:focus {
  color: #111b53;
  border-color: transparent;
}

.upladIcon {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
}

.uploadPreview {
  margin-bottom: 1.5rem;
}

/******* pop up end **********/

/* Footer start  */
.footerBox {
  box-shadow: 0px -5px 16px -8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  background-color: #fff;
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 160px);
  color: #111b53;
  z-index: 1000;
}

.footerLink {
  text-decoration: underline;
}

.footerLink:hover,
.footerLink:focus {
  color: #2c406e;
}

/* Footer end  */

/*Loading css end*/
.waterMarkText {
  position: absolute;
  width: 90%;
  top: 30%;
  left: 5%;
  font-size: 25px;
  transform: rotateZ(-45deg);
  /* color: #e6e4e4; */
  font-family: '_Layiji Kutlaimuu v 2.02', sans-serif !important;
  font-weight: 500;
  color: #ff1519;
  line-height: 20px;
}

.waterMarkTextProfileMatch {
  position: absolute;
  width: 100%;
  top: 72px;
  left: 10px;
  font-size: 13px;
  transform: rotateZ(-45deg);
  color: #e6e4e4;
}

/*Loading css start from here*/
.loader-title {
  font-size: 14px;
  font-weight: normal;
}

.loader-content .loaderBar {
  text-align: center;
}

/*Loading css end*/

.apexcharts-datalabel-value {
  display: none;
}

/* color class Name */
.globalGrayscale {
  background: rgba(0, 0, 0, 0.1);
}

.apexcharts-title-text {
  display: none !important;
}

.drawingBuffer {
  position: absolute;
  top: 60px;
  left: 22px;
}

#makePdfDemo {
  position: absolute;
  z-index: -2000;
  left: -4000px;
}

.apexcharts-tooltip .apexcharts-active {
  color: #000;
}

#apexchartsidentityverificationsystem .apexcharts-tooltip-text-y-label,
#apexchartssimregCount .apexcharts-tooltip-text-y-label {
  display: none;
}

.moreDetailIcon {
  color: #111b53;
  padding: 0;
  border-radius: 0;
}

.tabOuterAdmin {
  min-height: unset;
  margin-bottom: 0;
}
.tabIndicatorAdmin {
  display: none;
}
.tabDefaultSecAdmin {
  /* border-left: 1px solid #C8C8C8; padding: 0 0.75rem; font-size: 1rem; min-width: unset; */
  min-height: 35px;
  line-height: 2rem;
  font-weight: 500;
  align-items: flex-start;
  /* padding-bottom: 12px; */
}
.tabDefaultSecAdmin:first-child {
  border-left: unset;
}
.tabDefaultSecAdmin.tabSelectedSecAdmin {
  color: #111b53;
  font-weight: 600;
}
.tabHeaderCardAdmin {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1440) {
  body * {
    font-size: 18px;
  }
  .cardConfigureText {
    font-size: 1.125rem !important;
  }
}

.TREND_COMPLETE_HIDE,
.TREND_IP_HIDE,
.TREND_REJECTED_HIDE,
.TREND_TOTAL_HIDE {
  opacity: 0;
}
.legends {
  cursor: pointer;
}
path.active,
circle.active {
  display: none;
}

/* Added by man */
/* .pageHeading{font-size: .7rem;} */
/* .pageTitle{ font-size: .5rem; text-transform: capitalize;} */
.brudCrumbs .MuiTypography-root {
  font-size: 0.8rem;
  font-weight: normal;
}
/* nav styling */
.dashboardSidebar {
  padding: 0px;
}
.dashboardSidebar .MuiListItemButton-root {
  height: 48px;
  display: flex;
  flex-direction: inherit;
  justify-content: space-between;
  align-items: center;
}
.dashboardSidebar .MuiListItemButton-root.active {
  background-color: #12508e;
}
.dashboardSidebar .MuiListItemButton-root.active:before {
  background-color: #4fbbc8;
}
.dashboardSidebar .MuiListItemIcon-root {
  margin-bottom: 0px;
}
.dashboardSidebar .MuiListItemText-root {
  text-align: left;
  padding-left: 10px;
}
.dashboardSidebar {
  padding: 0px;
}
.dashboardSidebar .MuiListItemButton-root {
  height: 48px;
  display: flex;
  flex-direction: inherit;
  justify-content: space-between;
  align-items: center;
}
.dashboardSidebar .MuiListItemButton-root.active {
  background-color: #12508e;
}
.dashboardSidebar .MuiListItemButton-root.active:before {
  background-color: #4fbbc8;
}
.dashboardSidebar .MuiListItemIcon-root {
  margin-bottom: 0px;
}
.dashboardSidebar .MuiListItemText-root {
  text-align: left;
  padding-left: 10px;
  text-transform: none;
}

/*  header holder */
.headerHolder {
  background: #fff;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 0 20px;
}
.cardWrap {
  box-shadow: none;
}
body .dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.cardWrap {
  box-shadow: none;
}

.pushNotification {
  position: fixed;
  width: 350px;
  /* height: 270px; */
  right: 31px;
  bottom: 37px;
  /* transform: translate(-150px, -135px); */
  transform: translateX(7px);
  transition: all ease-in .3s;
  z-index: 10000000;
}
.displayHidden {
  /* display: none; */
  transform: translateX(380px);
  transition: all ease-in .3s;
}

.notification-container {
  /* position: fixed;
  top: 20px;
  right: 20px;
  max-width: 100%;
  width: 100%;
  z-index: 999; */
}

.notification {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-top: 1px;
  padding-bottom: 4px;
  font-size: 12px;
}

.close-btn {
  cursor: pointer;
}

.notification-body {
  margin-top: 10px;
  font-size: 12px;
}

.answer-btn,
.reject-btn {
  background-color: #4caf50;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;
}

.reject-btn {
  background-color: #e80a0a;
}

.answer-btn:hover {
  background-color: #45a049;
}

.reject-btn:hover {
  background-color: #990a0a;
}

.cardCaptureTxt {
  border: 1px dashed #4fbbc8;
  background: #fff;
  border-radius: 0.5rem;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  min-height: 300px;
  padding-top: 10px;
  height: 490px;
  overflow-y: scroll;
  padding: 18px;
}

@keyframes blink {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}
.currentCaptureDoc {
  margin: 15px 0px;
  padding: 7px;
  border: 1px dashed #ddd;
  color: #00ad09;
  animation: blink 1s infinite; /* Adjust the duration as needed */
  font-weight: bold;
}

.sideBarIcon{ font-size: 1.4em }