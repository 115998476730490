/*Scrollbar css start from here*/
::-webkit-scrollbar {
    background-color: #fff;
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
}

::-webkit-scrollbar-button {
    display: none;
}

/*Scrollbar css end*/
body .noPadding {
    padding: 0;
}

body a {
    font-weight: 400 !important;
}

body .headerHolder {
    display: flex;
    background: #fff;
    justify-content: space-between;
    padding: 0 20px;
    height: 48px;
}
/* page heading  */
.pageHeading {
    -webkit-box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
    padding: 10px 1.5rem;
    background-color: #fff;
    margin: -23px -1.5rem 1.5rem -1.5rem;
}

body .searchInput {
    padding: 4px 0;
    /* font-size: 0.875rem; */
}

body .squareIconButton {
    border-radius: 3px;
    border: 1px solid #4fbbc8;
    padding: 6px;
    color: #4fbbc8;
    background-color: #fff;
}
.goBackBtn {
    border-radius: 3px;
    /* border: 1px solid #4fbbc8; */
    padding: 6px;
    color: #4fbbc8;
    background-color: #fff;
}
/* body .squareIconButton.exportIcon svg{fill: #4fbbc8;} */
/* body .squareIconButton.exportIcon a{line-height: 0.85;} */
body .squareIconButton:hover, body .goBackBtn:hover {
    /* border: 1px solid transparent; */
    background-color: #4fbbc8;
    color: #fff;
}
body .fixedBox { height: 75vh; overflow-y: auto;}
body .formLabel { 
    margin-bottom: 8px;
    color: #718096;
    font-size: 0.8125rem;
    font-weight: 300;
}
body .formRowHolder { width: 60%; padding-right: 70px; margin-bottom: 20px; position: relative;}
body .formRowHolder.fullrow { width: 100%; padding-right: 0;}
body .textInput {padding: 8.5px 10px; background-color: #fff; border-radius: 8px;}
body .textInputTable {padding: 6px 9px;}
body .addMoreIcon { position: absolute; top: 30px; right: 30px;}

body .bigInput {padding: 0.8rem; font-size: 1rem; border-color: #4FBBC8;}

.fileInput{display:none;}
body .menuOpened .MuiMenu-paper { overflow: visible;}
body .displayDateRange {
    position: absolute; 
    bottom: 8px;
    left: 33px;
    font-size: 14px;
    z-index: 999;
}
body .actionButton {
    border: 1px solid transparent;
    color: #fff;
    background-color: #0d4689;
}
body .actionButton:hover {
    border-color: #0d4689;
    background-color: #fff;
    color: #0d4689;
}

.mb-0 {margin-bottom: 0!important;}
.mb-1 {margin-bottom: 0.5rem;}
.mb-2 {margin-bottom: 1rem;}
.mb-3 {margin-bottom: 1.5rem;}
.mb-4 {margin-bottom: 2rem;}
.mb-5 {margin-bottom: 2.5rem;}
.mb-6 {margin-bottom: 3rem;}
.mb-7 {margin-bottom: 3.5rem;}
.mt-1 {margin-top: 0.5rem;}
.mt-2 {margin-top: 1rem;}
.mt-3 {margin-top: 1.5rem;}
.mt-4 {margin-top: 2rem;}
.mr-1 {margin-right: 0.5rem;}
.mr-2 {margin-right: 1rem;}
.mr-3 {margin-right: 1.5rem;}
.mr-4 {margin-right: 2rem;}
.ml-1 {margin-left: 0.5rem;}
.ml-2 {margin-left: 1rem;}
.ml-3 {margin-left: 1.5rem;}
.ml-4 {margin-left: 2rem;}

.pb-05 {padding-bottom: 0.25rem;}
.pb-1 {padding-bottom: 0.5rem;}
.pb-2 {padding-bottom: 1rem;}
.pb-3 {padding-bottom: 1.5rem;}
.pb-4 {padding-bottom: 2rem;}
.pt-05 {padding-top: 0.25rem;}
.pt-1 {padding-top: 0.5rem;}
.pt-2 {padding-top: 1rem;}
.pt-3 {padding-top: 1.5rem;}
.pt-4 {padding-top: 2rem;}
.pr-05 {padding-right: 0.25rem;}
.pr-1 {padding-right: 0.5rem;}
.pr-2 {padding-right: 1rem;}
.pr-3 {padding-right: 1.5rem;}
.pr-4 {padding-right: 2rem;}
.pl-05 {padding-left: 0.25rem;}
.pl-1 {padding-left: 0.5rem;}
.pl-2 {padding-left: 1rem;}
.pl-3 {padding-left: 1.5rem;}
.pl-4 {padding-left: 2rem;}

.borderBottom {border-bottom: 1px solid rgba(241, 243, 244, 1);}
.fontBold {
    font-weight: 700;
}
.textCenter {
    text-align: center;
}
/********* new style *********/
.greenBorder {
    border:1px solid #4fbbc8;
}
.positionRel {position: relative;}
.overflowVisible{overflow: visible;}
.textAlignCenter {text-align: center;}
.textAlignRight {text-align: right;}
.w-100 {width: 100%;}
.w-50 {width: 50%;}
.w-40 {width: 40%;}
.w-30 {width: 30%;}
.w-25 {width: 25%;}
.displayFlex {display: flex;}
.displayTable {display: table;}
.displayTableRow {display: table-row;}
.displayTableCell {display: table-cell;}
.alignItemCenter {align-items: center;}
.alignItemTop {align-items: flex-start;}
.justifyContentBetween {justify-content: space-between;}
.justifyContentCenter {justify-content: center;}
.justifyContentRight {justify-content: right;}
.flexWrap {flex-wrap: wrap;}
.footerBox {
    padding: 8px 20px; 
    text-align: center;
    border-top: 1px solid #eaedf1;
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    z-index: 999;
}
.footerContent {padding-left: 200px;}
.appSubmissionLinkB {
    border: 1px solid #eaedf1;
    border-radius: 4px;
    padding: 1.5rem 1rem;
    background-color: #eaedf1;
    /* color: #0d4689; */
    transition: all 0.3s;
    cursor: pointer;
}
.appSubmissionLinkB:hover {
    box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
    color: #0d4689;
    background-color: #fff;
}
.appSubLinkIcon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    /* color: #0d4689; */
}
.popupTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid #eaedf1; */
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}


.cardDis {
    /* border:1px solid #eaedf1; */
    border-radius: 1rem;
    padding: 1rem 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    min-height: 100px;
}

.disIcon {
    font-size: 1.25rem;
    color: #0d4689;
}
/* .headingBig {color: #0d4689; font-weight: 500;} */
.disIconOuter {    
    padding: 0.75rem;
    border-radius: 2rem;
    display: inline-flex; 
    align-items: center; 
    justify-content: center; 
    margin-right: 1rem;
}
.cardColor1 {background-color: #e2e8f0;}
.cardColor1 .disIconOuter {background-color: rgb(140 148 159 / 20%);}

.cardColor2 {background-color: #dae8f6;}
.cardColor2 .disIconOuter {background-color: rgb(84 124 162 / 20%)}

.cardColor3 {background-color: #dfd9e5;}
.cardColor3 .disIconOuter {background-color: rgb(160 128 192 / 20%);}

.cardColor4 {background-color: #d9e8d8;}
.cardColor4 .disIconOuter {background-color: rgb(91 134 88 / 20%);}

.headingSmall {font-size: 0.8rem;}



/*********** preview report start *********/ 
.viewLeadCard {
    border-radius:8px;
    border: 1px solid #4fbbc8;
    padding: 1.25rem 1.25rem;
    /* height: 99%; */
    position: relative;
    background-color: #fff;
    /* margin-top: -14.5px; */
}
.cardTopMinusMargin {margin-top: -14.5px; padding-top: 2rem;}
.viewCard {padding: 1rem 0.75rem 0.75rem ;}
.viewLabelIcon {
    margin-top:0.2rem; 
    margin-right: 0.8rem; 
    color: #718096;
}
.labelText {
    color: #718096;
}
.headingLineUp {
    position: relative; 
    background-color: #4fbbc8; 
    padding-left: 0.5rem; 
    padding-right: 0.5rem; 
    top: -32px; 
    z-index: 999;
    left: 0;
    color: #fff;
    display: inline;
    border-radius: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-weight: 500;
}
.headingLineUpOut {top:0; left: 1.25rem; display: inline-block;}
.viewLeadCardTableInner {
    overflow: hidden;
    padding: 1.5rem 0 0;
}
/*********** preview report end *********/ 

  .submitLeadToolbar {display: flex; justify-content: flex-end;}
  .topToolbar {display: flex; justify-content: flex-end;}
  .textareaBox {width: 100%;border-radius: 8px;border-color: rgba(145, 158, 171, 0.32);}
  .textareaBox:hover {border-color: rgba(0, 0, 0, 1);}
  .textareaBox:focus {border-color: #0d4689;}
  .disbursedText {color: #00ad09; margin-left: 0.5rem;}
  .rejectedText {color: #ce0101; margin-left: 0.5rem;}
  .statusBox {padding: 0.5rem 1rem; display: inline-flex; border-radius: 0.5rem; background-color: #edf8f9; margin-bottom: 1rem;
    border: 1px solid #4fbbc8; align-items: center;}
  .statusBoxSec {background-color: #f4eded; }

  
/*********login start here ************/
.mainBtn {
background-color: #0d4689; 
color: white; 
font-size: 14px; 
padding: 12px 20px; 
letter-spacing: 0.5px;
font-weight: 500;
border-radius: 8px;
display: inline-flex;
min-width: 100px;
line-height: unset;
text-decoration: unset;
box-shadow: unset;
}
.mainBtn:hover, .mainBtn:focus {background-color: #4fbbc8;}

.backgroundGreen {
    background-color: #4fbbc8 !important;
    color: #fff !important;
  }

/* .formGroup {margin-bottom: 1.25rem;} */
/* .formControl {padding-top: 10px; padding-bottom: 10px; font-size: 14px; color: #2e3192; height:unset !important;} */
.dissabledMenu  {color: #919eab;}
/* .FormLabel {color: #999;} */

.otherBtn {background-color: #4FBBC8; padding:0.5rem 1rem;}
.otherBtn:hover, .otherBtn:focus {background-color: #0d4689;}
.redBtn {background-color: #ce0101;}

.mainBtnBig {padding: 0.863rem; width: 100%;}
/*********login end here ************/

/******* Login New Style Start here ********/
.LoginContainer {
    /* background-image: url("../public/static/login/login-bg.jpg");  */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
  }
  .loginBox{
    margin: auto;
    box-shadow: 1.5px 3.99px 27px 0px rgb(0 0 0 / 10%);
    transition: 0.3s;
    background-color: rgba(255, 255, 255, 0.72);
    align-items: center;
    margin: 1.25rem 0;
  }
/* .loginTitle {display: flex; align-items: center; margin-bottom: 1.25rem;} */
.loginTitle img {height: 25px; margin-left: 8px; margin-top: 2px;}
.loginSubTitle {margin-bottom: 2.5rem;}
.LoginOuter {background-color: #eff1f5;}
.loginInner {height: 100vh; width: 100%;}
.formGroupLogin {position: relative;}
.formControlFull {
  border: unset; background-color: #fafafc; padding: 1rem 1rem 1rem 3rem;
  font-size: 1rem; border: 1px solid #d8e4e4; border-radius: 8px; background-color: #fff;
}
.formControlIcon {position: absolute; left: 1rem; top: 1rem;z-index: 999; color: #4fbbc8;}
.formControlFull:focus {background-color: #f0f8f7; border-color: #4fbbc8;}
.loginLink {color: #4fbbc8; text-decoration: unset;}
.loginLink:hover, .loginLink:focus {color: #4fbbc8; text-decoration: unset;}
.loginBtn {
    /* background: rgb(0,212,255);
    background: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(141,218,213,1) 100%); */
    background-color: #4FBBC8;
    color: white; 
    font-size: 14px; 
    padding: 12px 20px; 
    letter-spacing: 0.5px;
    font-weight: 500;
    border-radius: 8px;
    min-width: 100px;
    line-height: unset;
    text-decoration: unset;
    width: 100%;
    transition: all 0.3s;
}
.loginBtn:hover, .loginBtn:focus {
    background-color: #54d4e2;
    /* background: rgb(141,218,213);
    background: linear-gradient(90deg, rgba(141,218,213,1) 0%, rgba(0,212,255,1) 100%); */
  }
.orDivider {position: relative; text-align: center;}
.orDivider::before, .orDivider::after {
      content: ""; 
      position: absolute; 
      top: calc(50% - 0.5px);
      height: 1px; 
      width: 42%; 
      background-color: #d8e4e4;
}
.orDivider::before {left: 0;}
.orDivider::after {right: 0;}
.loginBanner {height: 100vh; width: 100%; 
/* background-image: url("../public/static/login/referenceData.jpeg");  */
background-position: left -50px center; background-repeat: no-repeat; background-size: cover; position: relative;
display: flex; justify-content: center; align-items: center; flex-direction: column;
text-align: center; color: #fff;}
.loginBanner:after {background-color: rgba(0, 0, 0, 0.0); height: 100%; width: 100%; content: "";
left: 0; right: 0; bottom: 0; top: 0; position: absolute;z-index: 0;}
.loginBanner * {color: #fff; position: relative; z-index: 999;}
  
/******* Login New Style End here ********/

/******* Sign Up Style Start here ********/
.signUpOuter {
    background-color: #eff1f5;
    background-image: linear-gradient(45deg, #0d4689, #0d4689);
    background-size: 500px 100%;
    background-repeat: no-repeat;
    background-position: left;
    position: relative;
}
.signUpInner {
    padding-right: 80px;
    padding-left: 420px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: auto;
    height: 100vh;
}
.signUpLeft {
    position: fixed;
    left: 80px;
    top: 0;
    bottom: 0;
    width: 420px;
    z-index: 999;
}
.signUpLogo {
    height: 2.5rem;
    width: auto;
}
.signUpleftInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    z-index: 1;
    height: 100%;
}

.docList{
    margin-bottom: 2rem;
}
.docListItem{
    margin-top: 1.25rem;
    font-size: 1rem;
    color: #fff;
}
.docListItem:last-of-type{
    margin-bottom: none;
}
.docListText {
    font-size: 1rem;
}
.readyBtn {
    background-color: #fff;
    color: #0d4689;
    padding: 0.5rem 1rem;
    font-size: 0.8125rem;
    border-radius: 0.25rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 1.5;
    border: 1px solid #fff;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
.readyBtn:hover {
    background-color: transparent;
    color: #fff;
}
.signUpRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.signUpRightInner {
    position: relative;
}
.signUpHeading {
    color: #4fbbc8;
    /* color: #fff; */
    width: 320px;
}
.signUpRight .video-react .video-react-video, 
.signUpRight .video-react .video-react-poster,
.signUpRight .video-react.video-react-fluid {
    border-radius: 0.75rem;
    border: unset;
    outline: unset;
}
.signUpRight .video-react-has-started .video-react-control-bar {
    border-radius: 0 0 0.75rem 0.75rem;
    z-index: 999;
}
.signUpRight .video-react .video-react-big-play-button {
    top: calc(50% - 22.5px);
    left: calc(50% - 45px);
    z-index: 9999;
}
.closeVideoOut {
    position: absolute;
    right: -1rem;
    top: -1rem;
    background-color: #0d4689;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.closeVideoIcon {
    font-size: 0.8125rem;
    color: #fff;
}

.signUpFormRight {
    background-color: #fff;
    border-radius: 0.75rem;
    padding: 4rem 5rem;
    min-height: 450px;
}
.signUpFormStep {
    display: flex; 
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}
.signUpFormStep {
    text-decoration: none;
    color: #fff;
}
.signUpFormStep:hover, .signUpFormStep.active {
    color: #4fbbc8;
}
.signUpFormTextBox {
    width: 310px;
    padding-right: 1rem;
}
.signUpFormText {
    font-size: 1rem;
    font-weight: 500;
}
.signUpFormIconOut {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgb(208 208 208 / 50%);
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    align-items: center;
    justify-content: center;
}
.signUpFormStep:hover .signUpFormIconOut, .signUpFormStep.active .signUpFormIconOut {
    background-color: #4fbbc8;
    color: #fff;
}
.signUpFormIconOutActive {
    background-color: #4fbbc8;
}
.signUpFormIcon {}
.signUpFormTextSec {
    /* color: #4fbbc8; */
    color: #718096;
}
.partnerTypeCard {
    border: 1px solid #eaedf1;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 150px;
    display: flex;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.partnerTypeCard.active {
    background-color: #4fbbc8; color: #fff; border-color: #4fbbc8;
}
.partnerTypeCard:hover {
    border-color: #4fbbc8;
}
.uploadDocBoxLabel {
    border: 1px solid #eaedf1;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 150px;
    display: flex;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.uploadDocBoxLabel:hover {
    border-color: #4fbbc8;
}
.colorGreen {color: #4fbbc8}
.uploadDocBoxLabelFull {width:auto; height: 250px;}
/******* Sign Up Style End here ********/

/*********** timeline **********/
.timelineB {position: relative; overflow-x: auto; align-items: flex-start;
    display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-flow:row; width:100%;}
.timelineContentB::after {position: absolute; content: ''; height:2px;  background-color: #eaedf1; top: 2.25rem; left: 0; right: 0;}
.timelineContentB {display: flex; flex-direction: column; justify-content: center; min-width:210px; text-align: center; 
    position: relative; padding: 0 1rem 1rem;}
.timelineContentB::before {position: absolute;content: ''; width: 16px; height: 16px; border-radius: 50%; 
top: 1.8rem; left: calc(50% - 8px); background-color: #eaedf1;z-index: 999;}
.timelineContentBGreen::before {background-color: #00ad09;}
.timelineContentBOrange::before {background-color: #f36900;}
.date {margin-bottom: 2rem;}
/*********** timeline **********/
.statusB {padding: 0.5rem 1rem; display: flex; border-radius: 0.5rem; background-color: #eef4ed; margin-bottom: 1.5rem;}
.mainBtnSmall {width: 75px; margin: 0 auto;}


/******** review start ********/
/* .cardOuter {border-radius: 0.5rem; padding: 1rem; border:1px dashed #eaedf1; background-color: #fff;} */
.profileImg {height: 175px; border:1px solid #eaedf1; border-radius: 0.25rem; margin-bottom: 0.5rem;}
.circleProgress {position: relative; width: 120px; height: 120px; text-align: center; margin-left: auto; margin-right: auto;}
.circleProgress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.progressValue {position: absolute; top: calc(60px - 18px); left: calc(60px - 23px);}
.documentImg {
  border: 6px solid #f8f8f8;
  box-shadow: 0 0 20px -8px rgb(0 0 0 0/ 10%);
  border-radius: 4px;
  width: 100%;
}
/******** review end ********/


/********wizrd css start here **********/

.stepWizardOuter  {
    background: #e2e8f0;
    border-radius: 0.5rem;
  }
.stepWizardOuter .stepWizard  {
padding: 1rem;
border-radius: 0.5rem;
margin-right: 2rem;
}

.MuiStepIcon-root {
color: rgba(0, 0, 0, 0.38);
display: block;
} 

.previousBtn {background-color: #f9f9f9; color: #2e3192;}
.previousBtn:hover, .previousBtn:focus {background-color: #e9e9e9; color: #2e3192;}
.nextBtn {margin-left: auto;}
.uploadDummyImg {height: 100px; margin-bottom: 1.5rem; display: inline-block; border-radius: 8px;
border:1px solid #f0f0f0;}
.uploadCardLink {justify-content: center; align-items: center;}
.homeCardLink.uploadCardLink .linkIcon{margin-left: 3px; font-size: 21px;}
.loginOtpList{font-size: 14px;}

.docIcon {font-size: 1.25rem; color: #2e3192;}
.tableHead {background-color: #f9f9f9;}
.docApplicantName {color: #2e3192; font-size: 1rem;}


.PopupContent {min-width: 320px; width: 540px;}
.uploadFilePreview, .uploadPopupRow {
    border: 1px solid #f0f0f0;
    border-radius: .5rem;
}
.uploadFilePreview {
background-color: #edf8f9;
cursor: pointer;
height: 84px;
margin-right: 1rem;
padding: 0.5rem;
text-align: center;
transition: all .3s;
width: 84px;
}
.docName {line-height: 16px; font-size: 0.75rem;}
.ImageViewIcon {font-size: 1.5rem;}
.uploadFilePreviewGreen {border-color: #00ad09;}
.uploadFilePreviewRed {border-color: #ce0101;}
.uploadFilePreview:hover {background-color: #fff; box-shadow: 0 0 20px -8px rgba(0,0,0,0.2);}
.UploadPopupIcon {font-size: 1.25rem; margin-right: 1rem; width: 40px; height: 40px; text-align: center;
background-color: #fff; border-radius: 1.25rem; display: inline-flex; align-items: center; justify-content: center;
box-shadow:0 0 10px -2px rgb(0 0 0 / 10%);-webkit-box-shadow:0 0 10px -2px rgb(0 0 0 / 10%);}
.UploadPopupIcon img {width: 28px; display: inline;}
.UploadIcon {font-size: 28px; color: #2e3192;}
.Fileupload {margin-left: 1rem;}
.PassProtectB {width: 100%; display: flex; align-items: center; font-size: 0.75rem; flex-direction: row;}
.uploadPopupInnerRow {display: flex; align-items: center;}
.outerTableBox {border: 1px solid #f0f0f0; border-radius: 0.5rem;}
.documentUploadedBox {padding: 1rem;}
/* .formControlRemark {padding: 5px 10px;} */
.popupHeading {border-bottom: 1px solid #2e3192; margin-bottom: 1rem; display: flex; 
align-items: center; justify-content: space-between;}
.formLabel1 {margin-bottom: 0.5rem;}
.hideForView {display: none;}
.TextField {border: 1px solid #f0f0f0; border-radius: 0.5rem; padding: 0.4rem 0.75rem; background-color: #fff; font-size: 0.875rem;}
.uploadBtn {background-color: #2e3192; color: #fff; padding-left: 0.75rem; padding-right: 0.75rem;margin-left: 0.75rem;}
.uploadBtn:hover,.uploadBtn:focus {background-color: #ec3a46; }
.TextFieldGroup {border: unset;}
.FileTypeName {white-space: nowrap;}

/********wizrd css end here **********/

/********* integration card design *********/
.cardTop {padding: 1rem;}
/* .cardTop {display: flex; align-items: center;} */
.cardIconIcon {
    /* align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgb(0 0 0 / 6%);
    color: #0f478a;
    display: flex;
    height: 80px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 80px; */
    font-size: 2rem;
}

.cardIconInner {
    background-color: #f5f5f5;
    height: 50px;
    text-align: center;
    width: 50px;
    border-radius: 50%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #111B53;
  }

.cardBottom {
    align-items: center;
    border-top: 1px dashed #4fbbc8;
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 1rem;
}
.PaddingBottom, .cardTopPaddingBottom {
    padding-bottom: 0.25rem;
}
.facebook {color: #3b5998;}
.instagram {color: #fccc63;}
.youtube {color: #ff0000;}
.callCenter {color: #4fbbc8;}
.cardText {font-size: 0.875rem;}

.cardTextSmall {
    font-size: 12px;
}

.applicantImg {
    height: auto;
    width: 100%;
    max-width: 200px;
    border: 1px solid #eaedf1;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
}

.tableBox {
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
}

.previewImage {max-height: 208px; width: 100%;}
.subHeading {
    border: 1px solid #4fbbc8;
    background-color: #edf8f9;
    border-radius: 0.5rem;
    padding: 0.5rem;
    text-align: center;
}
.sliderPaperB {
    border-right: unset;
}

.decisionImg {filter: invert(1);}
.actionButton:hover .decisionImg {filter: invert(0);}

.textInputSmall {
    background-color: unset !important;
    border: unset;
    color: #fff;
    border: unset !important;

}
.dateStatusB {
    background-color: #f0f8f7; border: 1px solid #e2e8f0; border-radius: 4px; padding: 6px;
}

.yesNoBtn, .yesNoBtn:hover, .yesNoBtn:focus  {
    background-color: #edf8f9; 
    letter-spacing: 0.5px;
    font-weight: 500;
    border-radius: 8px;
    color: #0d4689;
  }
  .yesNoBtn.active {
    background-color: #0d4689;
    color: #fff;
  }
  
  
/* tabs  */
.tabOuter {min-height: unset;}
.tabOuterSec {padding: 0.5rem 1rem 0;}
.tabDefault {
    /* border: 1px solid #4fbbc8;  */
    border-radius: 0.25rem; 
    background-color: rgb(79, 187, 200, 0.4); 
    padding: 0.5rem; 
    font-size: 0.8175rem;
    min-height: unset; 
    line-height: unset; 
    color: #fff;
    margin-right: 1rem;
    font-weight: normal;
    min-width: 140px;
}
.tabDefault.tabSelected {background-color: #4fbbc8; color: #fff;}
.tabIndicator {display: none;}
.tabFlexPosition {justify-content: right;}
/* tabs  */

.scenarioParent .cardSmallNew {height: 100%;}
.cardSmallNewLink {cursor: pointer; min-height: 136px;}
.cardSmallNew {border: 1px solid #4fbbc8; border-radius: 8px; background-color: #fff; padding: 1rem; position: relative;
    min-width: 140px;}
.cardSmallNewSec {padding: 0.75rem}
.cardSmallNew::before {position: absolute; height: 50px; width: 5px; border-radius: 2px; background-color: #4fbbc8; 
    left: -3px; top: 10px; content:"";}
.cardSmallNew:hover {box-shadow: 0 0 5px 0 #4fbbc8;}

.darkBGCard {background-color: #4fbbc8; color: #fff;}


.areaChartSubheader {color: #0d4689; font-weight: bold; font-size: 0.9rem;}
.areaChart {margin-left: -20px; margin-bottom: -25px; margin-top: -25px;}
.rightBorder {border-right: 1px solid #0d4689; padding-left: 1rem;}
.alertsBox {border-left: 1px solid rgba(255, 255, 255, 0.25);padding-left: 1rem;}
.firstAlertsBox.alertsBox {border-left: unset; padding-left: unset;}


.tabDefaultSec {
    border-bottom: 2px solid transparent; padding: 0.2rem 0; font-size: 0.8175rem; min-width: unset;
    min-height: unset; line-height: unset; margin-right: 1rem; font-weight: 500;
}
.tabDefaultSec.tabSelectedSec {border-color: #0d4689; color: #0d4689; font-weight: 600;}

.colorBlue {
    color: #0d4689;
}
.mainBtnSec {
    background: #dae0f5;
    color: #0d4689;
    font-size: 12px;
    padding: 0.4rem 0.75rem;
}
.mainBtnSec:hover {
    background-color: #0d4689; color: #fff;
}

.leftBox {
    flex: 1 1;
    padding: 0.75rem;
}
.rightBox {
    border-left: 1px solid #4fbbc8;
    width: 300px;
    padding: 0.75rem;
}
.rightBoxHeading {
    background-color: #4fbbc8;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    z-index: 999;
    color: #fff;
    border-radius: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-weight: 500;
    text-align: center;
}

.alertsB {border: 1px solid #edf8f9; background-color: #edf8f9; border-radius: 0.5rem; padding: 0.75rem; transition: all 0.3s;}
.alertsB:hover {background-color: #fff; border-color: #d8e4e4; box-shadow: 0 0 16px -8px rgba(0, 0, 0, 0.25);}
.statusLink {
    color: #4fbbc8;
    cursor: pointer;
    font-size: 11px;
    font-weight: 600;
    text-decoration: underline;
}
.statusLink:hover {
    color: #0d4689; font-size: 11px; cursor: pointer;
}

.topToolbarIconBox {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.rangerSlideBox {
    border: unset;
    box-shadow: unset;
    border-radius: unset;
}

.rangerSlideBox .bar-inner {
    background-color: #0d4689;
    border: unset;
    height: 10px;
    box-shadow: unset;
}
.rangerSlideBox .thumb::before {
    background-color: #4FBBC8;
    border: unset;
    box-shadow: unset;
    margin: -5px -10px;
}
.rangerSlideBox .bar-right, .rangerSlideBox .bar-left {
    box-shadow: unset;
    background-color: #d8e4e4;
}

.rangerSlideBox .ruler .ruler-rule {
    border-bottom-color: #d8e4e4;
    border-left-color: #d8e4e4;
}

.rangerSlideBox .ruler .ruler-rule:last-child {
    border-right-color: #d8e4e4;
}

.grayBorder {
    border: 1px solid rgba(145,158,171,.32);
}
.arrowB {
    border: 2px solid #4fbbc8;
    border-top: unset;
    height: 55px;
    margin: 0 auto;
    position: relative;
    width: 60%;
}
.centerVLine {
    padding-top: 50px;
    position: relative;
}
.arrowB:after, .arrowB:before {
    border-color: transparent transparent #4fbbc8;
    border-style: solid;
    border-width: 0 8px 10px;
    content: "";
    height: 0;
    position: absolute;
    top: 0;
    width: 0;
}
.arrowB:before {
    left: -8px;
}
.arrowB:after {
    right: -8px;
}
.centerVLine:before {
    background-color: #4fbbc8;
    content: "";
    height: 50px;
    left: 50%;
    position: absolute;
    top: 0;
    translate: (0,-50%);
    width: 2px;
}

.signBox {
    align-items: center;
    background-color: #edf8f9;
    border-radius: 0.5rem;
    display: flex;
    height: 80px;
    justify-content: center;
    margin-bottom: 0.75rem;
    padding: 0.5rem 1rem;
    width: 100%;
}

.squareSmallIcon {
    border-radius: 8px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 999;
}

.squareSmallIcon .squareIconButton {
    padding: 2px;
    background-color: #4fbbc8;
    color: #fff;
}

.squareSmallIcon .squareIconButton:hover {
    padding: 2px;
    background-color: #fff;
    color: #4fbbc8;
    border-color: #4fbbc8;
}

.collapseIcon {
    border: 1px solid transparent;
    color: #fff;
    background-color: #4fbbc8;
    transition: all 0.3s;
    padding: 4px;
}

.labelValue {
    white-space: normal;
}

.uploadBox {
    border-radius: 0.5rem; 
    text-align: center; 
    /* padding: 1.5rem;  */
    cursor: pointer;
    background-color: #fff;
    transition: all 0.3s;
    height: 100%;    
    /* box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2); */
    color: #4fbbc8;
    border:1px solid #4fbbc8;
}
.uploadBox:hover, .uploadBox:focus{
    color: #0d4689;
    border-color: #0d4689;
}

.uploadBoxSec {
    height: 120px;
}
.uploadBoxSec label {
    padding: 1rem;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.upladIcon {
    /* margin-bottom: 0.5rem;  */
    font-size: 2.5rem;
}

.uploadPreview {
    margin-bottom: 1.5rem;
}

.downloadBtn {
    padding-top: 1rem !important; 
    padding-bottom: 1.5rem !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.downloadIcon {
    margin-bottom: 0.5rem; 
    font-size: 2.5rem;
}
.downloadBtnSec {
    height: 120px;
    padding: 0.75rem 2rem;
}

body .displayDateRangeSec {
    left: 12px;
}


.drag-and-drop {display: flex; justify-content: center; align-items: center;}
.source-box, .destination-box {
    padding: 1rem; border-radius: 0.5rem; background-color: #edf8f9; height: 250px;  
    border: 1px solid #4fbbc8; min-width: 260px; overflow: auto;
}
.listB {list-style: none;}
.listB li{font-size: 0.8175rem; margin-bottom: 0.5rem; cursor: pointer;}
.listB li:hover{color: #4FBBC8;}
.listB li:last-child {margin-bottom: 0;}

.statusGreen {
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 8px;
    color: rgb(34, 154, 22);
    font-size: 0.75rem;
    font-family: Poppins, sans-serif;
    background-color: rgba(84, 214, 44, 0.16);
    font-weight: 700;
}
.statusRed {
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 8px;
    color: rgb(183, 33, 54);
    font-size: 0.75rem;
    font-family: Poppins, sans-serif;
    background-color: rgba(255, 72, 66, 0.16);
    font-weight: 700;
}
.statusYellow {
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 8px;
    color: rgb(183, 129, 3);
    font-size: 0.75rem;
    font-family: Poppins, sans-serif;
    background-color: rgba(255, 193, 7, 0.16);
    font-weight: 700;
}

.cardBgS {
    background-color: #edf8f9;
    padding: 1.5rem 2.5rem;
    text-align: center;
    transition: all .3s;
    border: 1px solid #f0f0f0;
    border-radius: 0.5rem;
}
.cardBgS:hover {
    background-color: #fff;
    box-shadow: 0 0 20px -8px rgba(0,0,0,.2);
}

.greenColor {color: #00ad09;}
.redColor {color: #ce0101;}
.alertsHover {cursor: pointer;}
.alertsHover:hover {color: #0d4689;}

.newReportLinkB {
    background-color: #eaedf1;
    border: 1px solid #eaedf1;
    border-radius: 4px;
    cursor: pointer;
    padding: 1.5rem 1rem;
    transition: all .3s;
}

.newReportLinkB:hover {
    background-color: #fff;
    box-shadow: 0 0 20px -8px rgba(0,0,0,.2);
    color: #0d4689;
}

.newReportIcon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.barProgess {background-color: #d8e4e4; height: 5px; margin-bottom: 0.5rem; width: 100%; margin-top: -10px;}
.barProgressGreen {background-color: #a6ce39;}
.profileImgSec {
    border: 1px solid #eaedf1;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
}

.addBtn {
    background-color: #4fbbc8;
    border-radius: 8px;
    box-shadow: unset;
    color: #fff;
    display: inline-flex;
    line-height: unset;
    min-width: 100px;
    padding: 0;
    text-decoration: unset;
}

.addBtn:hover {
    background-color: #0d4689;
}

.addBtnSec {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0.75rem 1rem;
}
.profileImageBox {
    height: 100px;
    width: 90px;
}
.profileImage {
    border: 2px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    width: 90px;
}

.profileName {
    border-radius: 20px;
    color: #4fbbc8;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    top: -10px;
    width: 100%;
}

.cardSmallNew, .profileName {
    background-color: #fff;
    position: relative;
}



.greenSquare {width: 20px; height: 20px; background-color: #a6ce39; border: 1px solid #d8e4e4; border-radius: 4px 4px 0 0;}
.yellowSquare {width: 20px; height: 25px; background-color: #eae330; border: 1px solid #d8e4e4; border-radius: 4px 4px 0 0;}
.redSquare {width: 20px; height: 30px; background-color: #d60c17; border: 1px solid #d8e4e4; border-radius: 4px 4px 0 0;}
.squareLink {cursor: pointer;}
.yellowSquareS {width: 20px; height: 20px; background-color: #eae330; border: 1px solid #d8e4e4; border-radius: 4px 4px 0 0;}
.redSquareS {width: 20px; height: 20px; background-color: #d60c17; border: 1px solid #d8e4e4; border-radius: 4px 4px 0 0;}
.blankSquare {width: 20px; height: 20px; background-color: transparent; border: 1px solid transparent; border-radius: 4px 4px 0 0;}
.riskBox {height: 30px;}
.subHeadingBg {background-color: #d8e4e4 !important;}